<template>
    <div>
        <b-container>
            <div class="HeaderSection">
                <template v-if="isMobile(true)">
                    <b-row>
                        <b-col>
                            <h2 class="FullScheduleTitle" v-if="oomtitle !== undefined">
                            {{oomtitle}}
                            </h2>
                            <h2 class="FullScheduleTitle" v-else>
                            Main Order Of Merit
                            </h2>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!-- <b-col class="" style="margin-top:25px">
                            <a href="https://bushveldtour.bluegolf.com/bluegolf/bushveldtour24/poy/oom2324/index.htm" target="_blank" class="oomSeaonBtnlink">
                                <div class="oomSeasonBtn">
                                    2023 OOM Season
                                </div>
                            </a>
                        </b-col> -->
                        <b-col class="">
                            <b-nav card-header pills>
                                <b-dropdown :text="this.id + ' Season'">
                                    <b-dropdown-item
                                    v-on:click="changeYear"
                                    v-for="(seasons,
                                    index) in tm_params.seasons.seasons_entry.slice().reverse()"
                                    :key="index"
                                    :title="seasons.code"
                                    :year="seasons.code"
                                    >{{ seasons.desc }}</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-nav>
                        </b-col>
                    </b-row>
                </template>
                <template v-else>
                    <b-row>
                        <b-col>
                            <h2 class="FullScheduleTitle" v-if="oomtitle !== undefined">
                            {{oomtitle}}
                            </h2>
                            <h2 class="FullScheduleTitle" v-else>
                            Main Order Of Merit
                            </h2>
                        </b-col>
                        <!-- <b-col class="">
                            <a href="https://bushveldtour.bluegolf.com/bluegolf/bushveldtour24/poy/oom2324/index.htm" target="_blank" class="oomSeaonBtnlink">
                                <div class="oomSeasonBtn">
                                    2023 OOM Season
                                </div>
                            </a>
                        </b-col> -->
                        <b-col class="hideOnmob">
                            <b-nav card-header pills>
                                <b-dropdown :text="this.id + ' Season'">
                                    <b-dropdown-item
                                    v-on:click="changeYear"
                                    v-for="(seasons,
                                    index) in tm_params.seasons.seasons_entry.slice().reverse()"
                                    :key="index"
                                    :title="seasons.code"
                                    :year="seasons.code"
                                    v-show="seasons.code != '2023'"
                                    >{{ seasons.desc }}</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-nav>
                        </b-col>
                    </b-row>
                </template>
            </div>
            <table
                class="table table-striped"
            >
            <thead
            >
                <tr>
                <template v-if="isMobile(true)">
                    <th class="posCent">Pos</th>
                </template>
                <template v-else>
                    <th class="posCent">Position</th>
                </template>
                <th style="text-align: left">Name</th>
                <th>Nat</th>
                <template v-if="oomcashType == 'cash'">
                    <th class="hidemob posCent" style="text-transform: capitalize;">Prize Money</th>
                </template>
                <template v-else>
                    <th class="hidemob posCent" style="text-transform: capitalize;">{{oomcashType}}</th>
                </template>
                <th class="posCent">Played</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(oom, index) in data" :key="index">
                    <td class="posCent">{{ oom.pos }}</td>
                    <td style="text-align: left">
                        {{ oom.name }}
                        <span v-if="oom.sponsored === 'Y'">
                            <b-img class="sponsoredImage" :src="data.sponsor_indicator_logo"></b-img>
                        </span>
                    </td>
                    <td>
                        <span v-if="oom.nationality !== ''">
                            <img class="flag" :src="('https://assets.ocs-sport.com/flags/svg/flag_' + oom.nationality) | lowercase"
                        />
                        </span>
                    </td>
                    <td class="hidemob ">{{ oom.oom_value }}</td>
                    <td class="">{{ oom.played }}</td>
                </tr>
            </tbody>
            </table>
        </b-container>
    </div>
</template>
<script>
// import axios from 'axios';
import apiCall from "@/services/api.js";
export default {
  name: "OOM",
  props:["config"],
  components: {

  },
  data: function() {
    return {
        oomData: [],
        data: [],
        id: [],
        tm_params: [],
        reports: [],
        course: [],
        season: [],
        white: true,
        
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        apiCall
          .oom(id)
          .then(({ data }) => {
            this.data = data.oom.oom_entry;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
    async mounted() {
        try {
        // First call, await for call to return
        let first_call = await apiCall.tmParamsBase();
        this.tm_params = first_call.data;
        this.id = first_call.data.tm_params.season_code;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.oom(this.id);
        this.data = second_call.data.oom.oom_entry;
        this.oomtitle = second_call.data.full_name;
        this.oomcashType = second_call.data.oom_type;
        this.loading = false;
        } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
        }
    },
}
</script>
<style scoped>
::v-deep .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}
.oomSeasonBtn:hover {
    background-color: #668CE0;
}
a.oomSeaonBtnlink {
    text-decoration: none;
}
.oomSeasonBtn {
    background-color: #183167;
    border-color: rgb(255, 255, 255);
    font-size: 1rem;
    border-radius: 0px;
    width: 300px;
    color: #fff;
    text-align: center;
    padding: 5px;
    margin-top: 2px;
}
.FullScheduleTitle {
    font-weight: 700;
}
::v-deep .overflowPage {
  overflow: auto!important
}
::v-deep .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #668CE0;
    border-color: #668CE0;
}
::v-deep .dropdown-menu {
    width: 300px;
}
.sponsoredImage {
  width: 50px;
  margin-left: 10px;
}
::v-deep ul.nav.nav-pills.card-header-pills {
    float: right;
    margin-right: 0px;
}
::v-deep .btn {
    background-color: #183167;
    border-color: rgb(255, 255, 255);
    font-size: 1rem;
    border-radius: 0px;
    width: 300px;
}
::v-deep .btn:hover {
    background-color: #668CE0;
    border-color: rgb(255, 255, 255);
    font-size: 1rem;
    border-radius: 0px;
    width: 300px;
}
::v-deep .dropdown-toggle::after {
    margin-left: 10px;
}
.HeaderSection {
    margin-top: 3em;
    margin-bottom: 2em;
}
.posCent {
    text-align: center;
    color: #6c6c6c;
    font-weight: 700;
}
.flag {
    width: 30px;
}
td {
    padding: 11px;
    vertical-align: top;
    border-top: 0 solid #dee2e6;
    font-size: 10pt;
    border: 0px!important;
}
::v-deep tr:nth-child(odd) {
    background: rgba(0,0,0,.05);
}
::v-deep .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 0px;
}
::v-deep thead {
    /* background: pink; */
    /* color: #fff; */
    background-color: #3E3E3E;
    color: #fff!important;
    padding-bottom: 10px;
    font-size: 10pt;
    font-weight: 100!important;
}
th {
    font-weight: 400;
}
::v-deep .table-bordered th, .table-bordered td {
    border: 0px solid #dee2e6!important;
}
.table-bordered {
    border: 0px solid #dee2e6;
}

@media only screen and (max-width: 500px) {
    
    .oomSeasonBtn {
        background-color: #183167;
        border-color: rgb(255, 255, 255);
        font-size: 1rem;
        border-radius: 0px;
        width: 100%;
        color: #fff;
        text-align: center;
        padding: 5px;
        margin-top: 2px;
    }
    ::v-deep ul.nav.nav-pills.card-header-pills {
        float: none;
        margin-right: 14px;
        width: 100%;
        display: grid;
        margin-left: 0px;
        margin-top: 30px;
    }
    ::v-deep .dropdown-menu {
        width: 100%;
    }
}
</style>